import { CAMERA_STATE_CHANGE, DAILYCO_ID_CHANGE, IMAGE_STATE_CHANGE, NAME_STATE_CHANGE, UID_STATE_CHANGE } from "../constants"

const initialState = {
    uid: null,
    image: null,
    mic: null,
    camera: 'default',
    name: null,
    _id: null
}

export const user = (state = initialState, action) => {


    switch (action.type) {

        case UID_STATE_CHANGE:
            return {
                ...state,
                uid: action.uid,
            }
        case NAME_STATE_CHANGE:
            return {
                ...state,
                name: action.name,
            }
        case IMAGE_STATE_CHANGE:
            return {
                ...state,
                image: action.image,
            }
        case CAMERA_STATE_CHANGE:
            return {
                ...state,
                camera: action.camera,
            }
        case DAILYCO_ID_CHANGE:
            return {
                ...state,
                _id: action._id
            }

        default:
            return state
    }


}