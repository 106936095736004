import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {Auth0Provider} from "@auth0/auth0-react";

if(localStorage.getItem("redirect_uri")){
  let redirect_uri = localStorage.getItem("redirect_uri")
  localStorage.removeItem("redirect_uri")
  window.location.href = redirect_uri
}

ReactDOM.render(
  <React.StrictMode>
      <Auth0Provider
          domain="auth.mixronline.com"
          clientId="30wiZ8y72y5Ct9wzmBVOb6Fvm3RzVf9a"
          audience="https://mixr.backend"
          redirectUri={window.location.origin}
      >
      <App />
      </Auth0Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
