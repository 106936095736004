import { useAuth0 } from "@auth0/auth0-react";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { initializeApp } from "firebase/app";
import posthog from 'posthog-js';
import { React, useEffect, useState, lazy, Suspense } from 'react';
import { getPerformance } from "firebase/performance";
import { getAnalytics } from "firebase/analytics"
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { applyMiddleware, createStore } from 'redux';
import thunk from 'redux-thunk';
import './App.scss';
import rootReducer from './redux/reducers';
import Loading from "./Loading";
const ProtectedEvents = lazy(() => import("./gameTournament/components/_protectedEvents/protectedEvents"))
const ProtectedRecurring = lazy(() => import("./gameTournament/components/_protectedEvents/protectedRecurring"))
const ProtectedDashboard = lazy(() => import("./_dashboard/_protectedRoutes/protectedDashboard"))
const ProtectedData = lazy(() => import("./_dashboard/_protectedRoutes/protectedData"))
const PhoneConnect = lazy(() => import("./gameTournament/PhoneConnect"))
const RecurringEvent = lazy(() => import("./gameTournament/components/recurringEvent"))
const SlackLogin = lazy(() => import("./_dashboard/_admin/slack_login"))
const Logout = lazy(() => import("./_logout/logout"))

const store = createStore(rootReducer, applyMiddleware(thunk));
let firebaseConfig
//Change when we have another firebase project
if (process.env.REACT_APP_IS_PROD) {
    posthog.init(process.env.REACT_APP_POSTHOG_API, { api_host: 'https://app.posthog.com' })
    Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_URL,
        integrations: [new Integrations.BrowserTracing()],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
    });
    firebaseConfig = {
        apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
        authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
        databaseURL: process.env.REACT_APP_DATABASE_URL,
        projectId: process.env.REACT_APP_PROJECT_ID,
        storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
        messagingSenderId: process.env.REACT_APP_MSG_ID,
        appId: process.env.REACT_APP_APP_ID,
        measurementId: process.env.REACT_APP_MEASUREMENT_ID
    };

} else {

    // posthog.init('phc_fHrKzAjjis2Gz9GDeVKskliJQZVoMHNOEFqH24sZr00', {api_host: 'https://app.posthog.com'})
    firebaseConfig = {
        apiKey: "AIzaSyA4CwDwwQFrT16qxp7sAlFtR5riAWzExig",
        authDomain: "game-tournament-45ff3.firebaseapp.com",
        databaseURL: "https://game-tournament-45ff3-default-rtdb.firebaseio.com",
        projectId: "game-tournament-45ff3",
        storageBucket: "game-tournament-45ff3.appspot.com",
        messagingSenderId: "533908471659",
        appId: "1:533908471659:web:847b1fd24d4e79ca6e562e",
        measurementId: "G-2YBSN961YS"
    };


}






export function App() {
    const { user, isLoading } = useAuth0();
    const [firebaseApp, setFirebaseApp] = useState(initializeApp(firebaseConfig))
    const [returnToURL, setReturnToUrl] = useState(undefined)
    useEffect(() => {

        const params = new URLSearchParams(window.location.search)
        let returnTo = params.get("returnTo")
        if (returnTo && !returnToURL) {
            setReturnToUrl(returnTo)
        }
        if (user && returnToURL && returnToURL !== "/") {
            window.location.href = window.location.origin + returnToURL
        }

    }, [isLoading, user])


    useEffect(() => {
        try {
            getPerformance(firebaseApp)
            getAnalytics(firebaseApp)
        } catch (error) {
            console.log(error)
        }
    }, [firebaseApp])


    const params = new URLSearchParams(window.location.search)
    let auth = true
    if (params.get('isBot')) {
        auth = false
    }
    return (

        <Provider store={store}>
            <ToastContainer
                position="bottom-left"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss={false}
                draggable
                pauseOnHover={false}
            />
            <Router>
                <Suspense fallback={<div className="auth_container"><Loading /></div>} >
                    <Switch>
                        <Route path="/event/:id">
                            {/* <RecurringEvent /> */}
                            <ProtectedRecurring auth={auth} toast={toast} firebase={firebaseApp} />
                        </Route>
                        <Route path="/events/:id">
                            <ProtectedEvents auth={auth} toast={toast} firebase={firebaseApp} />
                        </Route>

                        <Route path="/phoneConnect">
                            <PhoneConnect firebase={firebaseApp} />
                        </Route>

                        <Route path="/slack/auth/redirect">
                            <SlackLogin type={"redirect"} code={params.get("code")} error={params.get("error")} />
                        </Route>

                        <Route path="/slack/add">
                            <SlackLogin type={"add"} />
                        </Route>

                        <Route path="/logout">
                            <Logout />
                        </Route>
                        <Route path="/data">
                            <ProtectedData firebase={firebaseApp} auth={auth} />
                        </Route>

                        <Route path="/">
                            <ProtectedDashboard auth={true} />
                        </Route>

                        

                    </Switch>
                </Suspense>
            </Router>
        </Provider >
    );

}

export default App;