import React, { useEffect, useState } from 'react';


export default function Loading(props) {

    var totalTime = 1500;

    const [text, setText] = useState("Loading   ")
    useEffect(() => {

        let t1 = null;
        let t2 = null;
        let t3 = null;
        let t4 = null;
        let t5 = null;
        let t6 = null;
        let t7 = null;

        t1 = setInterval(() => {
            setText("Loading   ")
        }, totalTime)
        t2 = setTimeout(() => {
            setText("Loading.")
            t5 = setInterval(() => {
                setText("Loading.")
            }, totalTime)
        }, ((totalTime / 4) * 1))

        t3 = setTimeout(() => {
            setText("Loading..")
            t6 = setInterval(() => {
                setText("Loading..")
            }, totalTime)
        }, ((totalTime / 4) * 2))
        t4 = setTimeout(() => {
            setText("Loading...")
            t7 = setInterval(() => {
                setText("Loading...")
            }, totalTime)
        }, ((totalTime / 4) * 3))

        return () => {
            setText("Loading   ")
            clearInterval(t1)
            clearTimeout(t2)
            clearTimeout(t3);
            clearTimeout(t4)
            clearInterval(t5)
            clearInterval(t6)
            clearInterval(t7)
        }

    }, [])
    return (
        <button className={props.inEvent !== true ? "loadingButton" : "loadingButtonEvent"}>{text}</button>
    )
}
